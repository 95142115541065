import {Spl22} from "@/api/fluxbeam/spl22";
import {baseURI} from "@/api/fluxbeam/constants";

export class FluxBeamClient {
	public spl22 = new Spl22()

	constructor() {
		//
	}

	async getPriorityFees() {
		const resp = await fetch(this.uri(`priority-fees`))
		if (resp.status != 200)
			throw resp.statusText

		return await resp.json()
	}

	async getTokenIndex(verifiedOnly = true, v2Only = true, page = 0, limit = 100) {
		return await (await fetch(this.uri(`tokens?limit=${limit}&page=${page}&verified=${verifiedOnly}&v2=${v2Only}`))).json()
	}

	async getTokenIndexAll(verifiedOnly = true) {
		let page = 0

		let resp = await (await fetch(this.uri(`tokens?limit=2000&page=${page}&verified=${verifiedOnly}`))).json()
		let toks = resp;

		const MAX_PAGES = 5
		while (resp.length === 2000) {
			if (page > MAX_PAGES)
				break //We only want to load MAX_PAGES to save on load time

			// console.debug("TOKS LEN", resp.length, toks.length)
			page++

			//@ts-ignore
			resp = await (await fetch(this.uri(`tokens?limit=2000&page=${page}&verified=${verifiedOnly}`))).json()
			//@ts-ignore
			toks = toks.concat(resp)
			// console.log("RLEN", resp.length)
		}

		// console.debug("TOKS TOTAL", toks.length)
		return toks
	}


	async getPools(page = 0, limit = 100, verifiedOnly = true) {
		return await (await fetch(this.uri(`pools?limit=${limit}&page=${page}&verified=${verifiedOnly}`))).json()
	}


	async searchPools(q: string, page = 0, limit = 100, verifiedOnly = true) {
		return await (await fetch(this.uri(`search/pools?limit=${limit}&page=${page}&q=${q}&verified=${verifiedOnly}`))).json()
	}


	async getPoolTokensIndex(fromPage: number = 0) {
		let page = fromPage
		let resp = await this.getPoolTokensIndexPage(page)
		let toks = resp;

		const MAX_PAGES = 5
		while (resp.length === 2000) {
			if (page > MAX_PAGES)
				break //We only want to load MAX_PAGES to save on load time

			// console.debug("TOKS LEN", resp.length, toks.length)
			page++

			//@ts-ignore
			resp = await this.getPoolTokensIndexPage(page)
			//@ts-ignore
			toks = toks.concat(resp)
			// console.log("RLEN", resp.length)
		}

		// console.debug("TOKS TOTAL", toks.length)
		return toks
	}

	async searchToken(query: string, page: number = 0) {
		return await (await fetch(this.uri(`search/tokens?q=${query}&page=${page}`))).json()
	}

	async getPoolTokensIndexPage(page: number, limit = 2000) {
		return await (await fetch(this.uri(`token_pools/details?limit=${limit}&page=${page}`))).json()
	}

	async getPoolTokenIndex() {
		return (await fetch(this.uri("token_pools/matrix"))).json()
	}

	async uploadTokenMetadata(name: string, symbol: string, description: string, imageData: any, image = "") {
		const data = new FormData()
		data.append('file', imageData)
		data.append('name', name)
		data.append('symbol', symbol)
		data.append('description', description)
		data.append('image', image)

		console.log("uploadTokenMetadata", data)
		return (await fetch(this.uri("tools/uploads/metadata"), {
			method: "POST",
			body: data
		})).json()
	}

	/**
	 * Notify the fluxbeam API of a new token event
	 * @param address
	 * @param signature
	 */
	async notifyTokenCreate(address: string, signature: string) {
		console.log("New token creation event!", {address, signature})

		const resp = await fetch(this.uri("tokens"), {
			method: "POST",
			headers: {
				//@ts-ignore
				"Content-Type": "application/json"
			},
			//@ts-ignore
			body: JSON.stringify({
				address: address,
				signature: signature
			})
		})
		if (resp.status != 200)
			throw resp.statusText


		return resp.json()
	}

	/**
	 * Notify the fluxbeam API that the metadata cid is confirmed
	 * @param cid
	 * @param signature
	 */
	async confirmMetadataPayment(cid: string, signature: string) {
		const resp = await fetch(this.uri("tools/uploads/metadata/confirm"), {
			method: "POST",
			headers: {
				//@ts-ignore
				"Content-Type": "application/json"
			},
			//@ts-ignore
			body: JSON.stringify({
				cid: cid,
				signature: signature
			})
		})
		if (resp.status != 200)
			throw resp.statusText


		return resp.json()
	}


	async getWithheldAmounts(mint) {
		const resp = await fetch(this.uri(`tokens/${mint}/withheld`))
		const j = await resp.json()
		if (j?.error)
			throw j.error

		return j
	}

	async getTokenAccounts(mint, cursor = "", limit = 250) {
		const resp = await fetch(this.uri(`tokens/${mint}/holders?cursor=${cursor}&limit=${limit}`))
		if (resp.status != 200)
			throw resp.statusText

		return await resp.json()
	}

	async getHolderCount(mint: string) {
		const resp = await fetch(this.uri(`tokens/${mint}/holders/count`))
		if (resp.status != 200)
			throw resp.statusText

		return resp.json()
	}

	async getHolders(mint: string, cursor = "", limit = 50) {
		const resp = await fetch(this.uri(`tokens/${mint}/holders?cursor=${cursor}&limit=${limit}`))
		if (resp.status != 200)
			throw resp.statusText

		return resp.json()
	}


	uri(endpoint: string) {
		return `${baseURI}/${endpoint}`
	}
}