import {web3} from "@project-serum/anchor"
import {
	AccountState,
	AuthorityType,
	createAssociatedTokenAccountInstruction,
	createBurnCheckedInstruction,
	createEnableRequiredMemoTransfersInstruction,
	createHarvestWithheldTokensToMintInstruction,
	createInitializeDefaultAccountStateInstruction,
	createInitializeImmutableOwnerInstruction,
	createInitializeInstruction,
	createInitializeInterestBearingMintInstruction,
	createInitializeMetadataPointerInstruction,
	createInitializeMintCloseAuthorityInstruction,
	createInitializeMintInstruction,
	createInitializeNonTransferableMintInstruction,
	createInitializePermanentDelegateInstruction,
	createInitializeTransferFeeConfigInstruction,
	createMintToInstruction,
	createSetAuthorityInstruction,
	createThawAccountInstruction,
	createTransferCheckedInstruction,
	createUpdateAuthorityInstruction,
	createUpdateFieldInstruction,
	createUpdateRateInterestBearingMintInstruction,
	createWithdrawWithheldTokensFromAccountsInstruction,
	createWithdrawWithheldTokensFromMintInstruction,
	ExtensionType,
	getAssociatedTokenAddressSync,
	getMintLen,
	TOKEN_2022_PROGRAM_ID,
	TOKEN_PROGRAM_ID
} from "@solana/spl-token";

import {createCreateMetadataAccountV3Instruction, createUpdateMetadataAccountV2Instruction, DataV2, Metadata} from "@metaplex-foundation/mpl-token-metadata";
import {METADATA_2022_PROGRAM_ID} from "@/api/token_swap/constants";
import {createSetTransferFeeInstruction} from "./instruction_ext";
import {CreateMintV1} from "@/api/token/token";
import {UpdateMetadataAccountArgsV2} from "@metaplex-foundation/mpl-token-metadata/dist/src/generated/types/UpdateMetadataAccountArgsV2";
import {pack} from "@solana/spl-token-metadata";
import {ComputeBudgetProgram, SystemProgram} from "@solana/web3.js";

export const TOKEN_METADATA_PROGRAM_ID = METADATA_2022_PROGRAM_ID

export class CreateMint extends CreateMintV1 {
	extensions: ExtensionType[] = []
	extensionConfig = {}

	metadata: any


	setMetadata(meta: any) {
		this.metadata = meta
	}

	metadataLength(): number {
		if (!this.metadata) {
			return 0
		}

		return pack({
			additionalMetadata: this.metadata?.additionalMetadata || [],
			mint: web3.PublicKey.default,
			symbol: this.metadata.symbol,
			name: this.metadata.name,
			uri: this.metadata.uri
		}).length + 4
	}

	addExtension(ext: ExtensionType, config: {} = {}) {
		this.extensions.push(ext)
		//@ts-ignore
		this.extensionConfig[ext] = config
	}
}

export class CreateMetadata {
	name = ""
	symbol = ""
	uri = ""
	sellerFeeBasisPoints = 0
	mutable = false
}

export class Token2022Client {


	FEE_ACCOUNT = new web3.PublicKey("FLUXR4McuD2iXyP3wpP4XTjSWmB86ppMiyoA52UA9bKb")

	connection: web3.Connection

	constructor(connection: web3.Connection) {
		this.connection = connection
	}

	isDevnet() {
		return this.connection.rpcEndpoint.indexOf("devnet") > -1;
	}

	metadataProgram(): web3.PublicKey {
		if (this.isDevnet())
			return new web3.PublicKey("M1tgEZCz7fHqRAR3G5RLxU6c6ceQiZyFK7tzzy4Rof4")
		return TOKEN_METADATA_PROGRAM_ID
	}

	/**
	 * Returns all token mints
	 * WARNING - Heavy RPC Call
	 */
	async getTokenIndex() {
		return this.connection.getParsedProgramAccounts(TOKEN_2022_PROGRAM_ID)
	}

	/**
	 * Returns the user token accounts
	 * @param address
	 */
	async getUserTokenIndex(address: web3.PublicKey) {
		const v1 = this.connection.getParsedTokenAccountsByOwner(address, {
			programId: TOKEN_PROGRAM_ID
		}, "processed")
		const v2 = this.connection.getParsedTokenAccountsByOwner(address, {
			programId: TOKEN_2022_PROGRAM_ID
		}, "processed")

		const resp = await Promise.all([v1, v2])
		if (!resp)
			return []

		return resp[0].value.concat(...resp[1].value)
	}

	async getTokenPrices(mints: web3.PublicKey[]) {
		mints.push(new web3.PublicKey("So11111111111111111111111111111111111111112"))

		let tokenPrices = {}
		const chunkSize = 100;
		for (let i = 0; i < mints.length; i += chunkSize) {
			const chunk = mints.slice(i, i + chunkSize);
			const resp = await fetch(`https://price.jup.ag/v4/price?ids=${chunk}`);
			const data = await resp.json();
			tokenPrices = {...tokenPrices, ...data.data};
		}
		return tokenPrices
	}

	/**
	 * Returns a token via mint
	 * @param mint
	 */
	async getToken(mint: web3.PublicKey) {
		return this.connection.getParsedAccountInfo(mint, {commitment: "processed"})
	}

	/**
	 * Returns a token via mint
	 * @param owner
	 * @param mint
	 * @param program
	 */
	async getUserTokenAccount(mint: web3.PublicKey, owner: web3.PublicKey, program = TOKEN_2022_PROGRAM_ID) {
		return this.connection.getParsedAccountInfo(this.getAssociatedTokenPDA(mint, owner, program), {commitment: "processed"})
	}


	/**
	 * Returns the token metadata via mint
	 * @param mint
	 * @param metadataProgram
	 */
	async getTokenMetadata(mint: web3.PublicKey, metadataProgram: web3.PublicKey = this.metadataProgram()) {
		return this.getTokenMetadataRaw(this.getMetadataPDA(mint, metadataProgram))
	}


	/**
	 * Returns the token metadata via mint
	 * @param metadataAddress
	 */
	async getTokenMetadataRaw(metadataAddress: web3.PublicKey) {
		return Metadata.fromAccountAddress(this.connection, metadataAddress, "confirmed")
	}


	/**
	 * Returns the balance of a given mint & owner
	 * @param mint
	 * @param owner
	 * @param program
	 */
	async getSolBalance(owner: web3.PublicKey) {
		return this.connection.getBalance(owner, "processed")
	}


	/**
	 * Returns the balance of a given mint & owner
	 * @param mint
	 * @param owner
	 * @param program
	 */
	async getTokenBalance(mint: web3.PublicKey, owner: web3.PublicKey, program = TOKEN_2022_PROGRAM_ID) {
		return this.connection.getParsedAccountInfo(this.getAssociatedTokenPDA(mint, owner, program), {commitment: "processed"})
	}

	/**
	 * Returns the file metadata for given token metadata
	 * @param metadata
	 */
	async getTokenFileMetadata(metadata: Metadata) {
		if (!metadata || !metadata.data.uri.replace(/\0.*$/g, ''))
			return

		return (await fetch(metadata.data.uri)).json()
	}


	/**
	 * Returns the token mints account for a given user
	 * @param owner
	 * @param mint
	 * @param program
	 * @param allowOwnerOffCurve
	 */
	getAssociatedTokenPDA(mint: web3.PublicKey, owner: web3.PublicKey, program = TOKEN_2022_PROGRAM_ID, allowOwnerOffCurve = false) {
		return getAssociatedTokenAddressSync(mint, owner, allowOwnerOffCurve, program)
	}


	/**
	 * Returns the token mints metadata PDA
	 * @param mint
	 * @param metadataProgram
	 */
	getMetadataPDA(mint: web3.PublicKey, metadataProgram = this.metadataProgram()) {
		//@ts-ignore
		const [metaPDA] = web3.PublicKey.findProgramAddressSync(["metadata", metadataProgram.toBuffer(), mint.toBuffer()], metadataProgram)
		return metaPDA
	}

	async getUpdateToken22MetadataTransaction(mint: web3.PublicKey, metadata: any, authority: web3.PublicKey, data: UpdateMetadataAccountArgsV2, priorityFee: number, metadataCID: string) {
		console.log("getUpdateToken22MetadataTransaction", {
			data,
			metadata
		})

		const transaction = new web3.Transaction()
		const unitLimit = 90_000
		const unitPrice = Math.floor(priorityFee / unitLimit)

		transaction.add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: unitLimit}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: unitPrice}),
			web3.SystemProgram.transfer({
				fromPubkey: authority,
				toPubkey: this.FEE_ACCOUNT,
				lamports: 100_000_000,
			}),
			new web3.TransactionInstruction({
				keys: [],
				programId: new web3.PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
				data: Buffer.from(metadataCID)
			}),
		)


		if (data.data.name != metadata.name) {
			console.debug("getUpdateToken22MetadataTransaction::name - ", data.data.name, metadata.name)
			transaction.add(createUpdateFieldInstruction({
				programId: TOKEN_2022_PROGRAM_ID,
				metadata: mint,
				updateAuthority: new web3.PublicKey(metadata.updateAuthority),
				field: "name",
				value: data.data.name
			}))
		}

		if (data.data.symbol != metadata.symbol) {
			console.debug("getUpdateToken22MetadataTransaction::symbol - ", data.data.symbol, metadata.symbol)
			transaction.add(createUpdateFieldInstruction({
				programId: TOKEN_2022_PROGRAM_ID,
				metadata: mint,
				updateAuthority: new web3.PublicKey(metadata.updateAuthority),
				field: "symbol",
				value: data.data.symbol
			}))
		}

		if (data.data.uri != metadata.uri) {
			console.debug("getUpdateToken22MetadataTransaction::uri - ", {
				data: data.data.uri, meta: metadata.uri
			})
			transaction.add(createUpdateFieldInstruction({
				programId: TOKEN_2022_PROGRAM_ID,
				metadata: mint,
				updateAuthority: new web3.PublicKey(metadata.updateAuthority),
				field: "uri",
				value: data.data.uri
			}))
		}


		console.debug("Adjust authority", data.updateAuthority, metadata.updateAuthority)
		if (data.updateAuthority != metadata.updateAuthority) {
			console.debug("getUpdateToken22MetadataTransaction::authority - ", metadata.updateAuthority, data.updateAuthority)
			const args = {
				metadata: mint,
				newAuthority: null,
				oldAuthority: new web3.PublicKey(metadata.updateAuthority),
				programId: TOKEN_2022_PROGRAM_ID
			}

			if (data.updateAuthority) {
				args.newAuthority = new web3.PublicKey(data.updateAuthority)
			}

			transaction.add(createUpdateAuthorityInstruction(args))
		}

		const oldMetadataLength = pack({
			additionalMetadata: metadata?.additionalMetadata || [],
			mint: web3.PublicKey.default,
			symbol: metadata.symbol,
			name: metadata.name,
			uri: metadata.uri
		}).length + 4

		const newMetadataLength = pack({
			additionalMetadata: [],
			mint: web3.PublicKey.default,
			symbol: data.data.symbol,
			name: data.data.name,
			uri: data.data.uri
		}).length + 4

		const mintLamports = await this.connection.getMinimumBalanceForRentExemption(newMetadataLength - oldMetadataLength);
		if (mintLamports > 0) {
			transaction.add(SystemProgram.transfer({fromPubkey: authority, toPubkey: new web3.PublicKey(mint), lamports: mintLamports}));
		}


		const bhash = await this.connection.getLatestBlockhash("confirmed")
		transaction.feePayer = authority
		transaction.recentBlockhash = bhash.blockhash

		return transaction
	}

	async getUpdateMetadataTransaction(metadata: web3.PublicKey, authority: web3.PublicKey, data: UpdateMetadataAccountArgsV2, metadataProgram = this.metadataProgram(), priorityFee, metadataCID: string) {
		const transaction = new web3.Transaction()

		const unitLimit = 100_000
		const unitPrice = Math.floor(priorityFee / unitLimit)

		transaction.add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: unitLimit}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: unitPrice}),
			web3.SystemProgram.transfer({
				fromPubkey: authority,
				toPubkey: this.FEE_ACCOUNT,
				lamports: 20000000,
			}),
			new web3.TransactionInstruction({
				keys: [],
				programId: new web3.PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
				data: Buffer.from(metadataCID)
			}),
			createUpdateMetadataAccountV2Instruction({
				metadata: metadata,
				updateAuthority: authority,
			}, {
				updateMetadataAccountArgsV2: data
			}, metadataProgram))

		const bhash = await this.connection.getLatestBlockhash("confirmed")
		transaction.feePayer = authority
		transaction.recentBlockhash = bhash.blockhash

		return transaction
	}


	async getBurnTransaction(mint: web3.PublicKey, srcOwner: web3.PublicKey, amount: number, decimals: number, program = TOKEN_2022_PROGRAM_ID) {
		const srcAta = this.getAssociatedTokenPDA(mint, srcOwner, program)
		const transaction = new web3.Transaction()
		transaction.add(createBurnCheckedInstruction(srcAta, mint, srcOwner, amount, decimals, [], program))


		const bhash = await this.connection.getLatestBlockhash("confirmed")
		transaction.feePayer = srcOwner
		transaction.recentBlockhash = bhash.blockhash

		return transaction
	}

	async getSendTransferTransaction(mint: web3.PublicKey, srcOwner: web3.PublicKey, dstOwner: web3.PublicKey, amount: number, decimals: number, program = TOKEN_2022_PROGRAM_ID, allowOwnerOffCurve = false) {
		const srcAta = this.getAssociatedTokenPDA(mint, srcOwner, program, allowOwnerOffCurve)
		const dstAta = this.getAssociatedTokenPDA(mint, dstOwner, program, allowOwnerOffCurve)

		const transaction = new web3.Transaction()

		const dstIfo = await this.connection.getAccountInfo(dstAta, "confirmed")
		if (!dstIfo) {
			transaction.add(createAssociatedTokenAccountInstruction(srcOwner, dstAta, dstOwner, mint, program))
		}

		transaction.add(createTransferCheckedInstruction(
			srcAta, //Src Ata
			mint, //Mint
			dstAta,
			srcOwner,
			amount, //TODO check decimals??
			decimals,
			[],
			program
		))


		const bhash = await this.connection.getLatestBlockhash("confirmed")
		transaction.feePayer = srcOwner
		transaction.recentBlockhash = bhash.blockhash

		return transaction
	}

	async getSendTransferSolTransaction(srcOwner: web3.PublicKey, dstOwner: web3.PublicKey, amount: number,) {
		const transaction = new web3.Transaction()

		transaction.add(
			web3.SystemProgram.transfer({
				fromPubkey: srcOwner,
				toPubkey: dstOwner,
				lamports: amount,
			}))


		const bhash = await this.connection.getLatestBlockhash("confirmed")
		transaction.feePayer = srcOwner
		transaction.recentBlockhash = bhash.blockhash

		return transaction
	}

	/**
	 * Returns array of transactions needed to claim all withheld tokens for a given mint
	 *
	 * @param mint
	 * @param payer
	 * @param authority
	 * @param srcAccounts
	 */
	async getClaimWitheldTokensTransaction(mint: web3.PublicKey, payer: web3.PublicKey, authority: web3.PublicKey, srcAccounts: web3.PublicKey[]) {
		const dstAcc = this.getAssociatedTokenPDA(mint, payer, TOKEN_2022_PROGRAM_ID)
		const transactions = []

		//TODO hoist to signing layer
		const bhash = await this.connection.getLatestBlockhash("confirmed")
		if (!await this.accountExists(dstAcc)) {
			const transaction = new web3.Transaction().add(
				ComputeBudgetProgram.setComputeUnitLimit({units: 42_000}),
				createAssociatedTokenAccountInstruction(
					payer,
					dstAcc,
					payer,
					mint,
					TOKEN_2022_PROGRAM_ID
				))

			//Withdraw from mint account too
			transaction.add(createWithdrawWithheldTokensFromMintInstruction(mint, dstAcc, payer, [], TOKEN_2022_PROGRAM_ID))

			transaction.feePayer = payer
			transaction.recentBlockhash = bhash.blockhash
			transactions.push(transaction)
		} else {
			//Withdraw from mint account too
			transactions.push(new web3.Transaction().add(
				ComputeBudgetProgram.setComputeUnitLimit({units: 10_000}),
				createWithdrawWithheldTokensFromMintInstruction(mint, dstAcc, payer, [], TOKEN_2022_PROGRAM_ID)
			))
		}

		for (let i = 0; i < srcAccounts.length; i += 30) {
			const transaction = new web3.Transaction().add(
				createWithdrawWithheldTokensFromAccountsInstruction(
					mint,
					dstAcc,
					authority,
					[],
					srcAccounts.slice(i, i + 30),
					TOKEN_2022_PROGRAM_ID
				)
			);

			transaction.feePayer = payer
			transaction.recentBlockhash = bhash.blockhash
			transactions.push(transaction)
		}

		return transactions
	}

	/**
	 * Returns transaction to claim withheld tokens from the mint account
	 *
	 * @param mint
	 * @param payer
	 */
	async getClaimWitheldTokensFromMintTransaction(mint: web3.PublicKey, payer: web3.PublicKey) {
		const dstAcc = this.getAssociatedTokenPDA(mint, payer, TOKEN_2022_PROGRAM_ID)
		const transaction = new web3.Transaction()

		//TODO hoist to signing layer
		const bhash = await this.connection.getLatestBlockhash("confirmed")
		if (!await this.accountExists(dstAcc)) {
			transaction.add(
				ComputeBudgetProgram.setComputeUnitLimit({units: 42_000}),
				createAssociatedTokenAccountInstruction(
					payer,
					dstAcc,
					payer,
					mint,
					TOKEN_2022_PROGRAM_ID
				))
		} else {
			transaction.add(ComputeBudgetProgram.setComputeUnitLimit({units: 10_000}))
		}


		//Withdraw from mint account too
		transaction.add(createWithdrawWithheldTokensFromMintInstruction(mint, dstAcc, payer, [], TOKEN_2022_PROGRAM_ID))

		transaction.feePayer = payer
		transaction.recentBlockhash = bhash.blockhash
		return transaction
	}

	//Permissionless version
	//Harvest tokens to mint
	async getClaimWithheldTokensToMintTransaction(mint: web3.PublicKey, payer: web3.PublicKey, srcAccounts: web3.PublicKey[]) {
		const transactions = []

		for (let i = 0; i < srcAccounts.length; i += 30) {
			const transaction = new web3.Transaction().add(
				createHarvestWithheldTokensToMintInstruction(
					mint,
					srcAccounts.slice(i, i + 30),
				)
			);

			const bhash = await this.connection.getLatestBlockhash("confirmed")
			transaction.feePayer = payer
			transaction.recentBlockhash = bhash.blockhash
			transactions.push(transaction)
		}
		return transactions
	}

	async getCreateMetadataTransaction(owner: web3.PublicKey, tokenMint: web3.PublicKey, config: CreateMetadata, metadataProgram = this.metadataProgram()) {
		const txn = new web3.Transaction()

		//Create token metadata
		const [metadataPDA] = web3.PublicKey.findProgramAddressSync([Buffer.from("metadata"), metadataProgram.toBuffer(), tokenMint.toBuffer()], metadataProgram)

		console.log("getCreateMetadataTransaction", config)
		const ON_CHAIN_METADATA = {
			name: config.name,
			symbol: config.symbol,
			uri: config.uri,
			sellerFeeBasisPoints: config.sellerFeeBasisPoints || 0,
			uses: null,
			creators: null,
			collection: null,
		} as DataV2;

		console.log("Creating metadata acc", ON_CHAIN_METADATA)
		txn.add(
			createCreateMetadataAccountV3Instruction({
				metadata: metadataPDA,
				mint: tokenMint,
				mintAuthority: owner,
				payer: owner,
				updateAuthority: owner,
			}, {
				createMetadataAccountArgsV3:
					{
						data: ON_CHAIN_METADATA,
						isMutable: config.mutable,
						collectionDetails: null
					}
			}, metadataProgram),
		)

		console.log("Getting blockhash")
		const bhash = await this.connection.getLatestBlockhash("confirmed")
		console.log("Getting blockhash", bhash)
		txn.feePayer = owner
		txn.recentBlockhash = bhash.blockhash
		return txn
	}

	async getCreateMintTransaction(owner: web3.PublicKey, tokenMint: web3.PublicKey, config: CreateMint, priorityFee: number, metadataCID: string) {
		const mintLen = getMintLen(config.extensions);
		const mintLamports = await this.connection.getMinimumBalanceForRentExemption(mintLen + config.metadataLength());

		const ata = getAssociatedTokenAddressSync(tokenMint, owner, true, TOKEN_2022_PROGRAM_ID)

		const ON_CHAIN_METADATA = {
			name: config.name,
			symbol: config.symbol,
			uri: config.metadataUri,
			sellerFeeBasisPoints: 0,
			uses: null,
			creators: null,
			collection: null,
		} as DataV2;


		const unitLimit = 120_000
		const unitPrice = Math.floor(priorityFee / unitLimit)
		const txn = new web3.Transaction().add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: unitLimit}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: unitPrice}),
			web3.SystemProgram.createAccount({
				fromPubkey: owner,
				newAccountPubkey: tokenMint,
				space: mintLen,
				lamports: mintLamports,
				programId: TOKEN_2022_PROGRAM_ID,
			}),
			web3.SystemProgram.transfer({
				fromPubkey: owner,
				toPubkey: this.FEE_ACCOUNT,
				lamports: 20000000,
			}),
			new web3.TransactionInstruction({
				keys: [],
				programId: new web3.PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
				data: Buffer.from(metadataCID)
			}),
		);

		let isDefaultFrozen = false;
		config.extensions.forEach((ext) => {
			//@ts-ignore
			const cfg = config.extensionConfig[ext]
			console.log(`${ext}`, cfg)

			switch (ext) {
				case ExtensionType.TransferFeeConfig:
					ON_CHAIN_METADATA.sellerFeeBasisPoints = cfg.feeBasisPoints //Update so it reflects same as royalties
					txn.add(createInitializeTransferFeeConfigInstruction(
						tokenMint,
						cfg.transfer_fee_config_authority ? cfg.transfer_fee_config_authority : config.mintAuthority, //Config Auth
						cfg.withdraw_withheld_authority ? cfg.withdraw_withheld_authority : config.mintAuthority, //Withdraw Auth
						cfg.feeBasisPoints,
						cfg.maxFee,
						TOKEN_2022_PROGRAM_ID
					))
					break
				case ExtensionType.InterestBearingConfig:
					txn.add(createInitializeInterestBearingMintInstruction(
						tokenMint,
						owner, //Rate Auth
						cfg.rate * 100,
						TOKEN_2022_PROGRAM_ID
					))
					break
				case ExtensionType.PermanentDelegate:
					txn.add(createInitializePermanentDelegateInstruction(
						tokenMint,
						new web3.PublicKey(cfg.address),
						TOKEN_2022_PROGRAM_ID
					))
					break
				case ExtensionType.NonTransferable:
					txn.add(createInitializeNonTransferableMintInstruction(
						tokenMint,
						TOKEN_2022_PROGRAM_ID
					))
					break
				case ExtensionType.ImmutableOwner:
					txn.add(createInitializeImmutableOwnerInstruction(
						tokenMint,
						TOKEN_2022_PROGRAM_ID
					))
					break
				case ExtensionType.MemoTransfer:
					txn.add(createEnableRequiredMemoTransfersInstruction(
						tokenMint,
						owner,
						[],
						TOKEN_2022_PROGRAM_ID
					))
					if (config.mintTotalSupply)
						txn.add(new web3.TransactionInstruction({
							keys: [{pubkey: owner, isSigner: true, isWritable: true}],
							data: Buffer.from("Mint To Memo", "utf-8"),
							programId: new web3.PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
						}))
					break
				case ExtensionType.DefaultAccountState:
					isDefaultFrozen = cfg.state === AccountState.Frozen;
					txn.add(createInitializeDefaultAccountStateInstruction(
						tokenMint,
						cfg.state,
						TOKEN_2022_PROGRAM_ID
					))
					break
				case ExtensionType.MintCloseAuthority:
					txn.add(createInitializeMintCloseAuthorityInstruction(
						tokenMint,
						config.mintAuthority,
						TOKEN_2022_PROGRAM_ID
					))
					break
				case ExtensionType.MetadataPointer:
					txn.add(createInitializeMetadataPointerInstruction(
						tokenMint,
						config.mintAuthority,
						tokenMint,
						TOKEN_2022_PROGRAM_ID
					))
					break
				default:
					console.error("Unsupported extension", ext)
					break
			}
		})

		//Init the mint
		txn.add(createInitializeMintInstruction(tokenMint, config.decimals, config.mintAuthority, config.freezeAuthority, TOKEN_2022_PROGRAM_ID))

		if (config.metadata) {
			txn.add(createInitializeInstruction({
				programId: TOKEN_2022_PROGRAM_ID,
				metadata: tokenMint,
				updateAuthority: config.mintAuthority,
				mint: tokenMint,
				mintAuthority: config.mintAuthority,
				name: config.metadata.name,
				symbol: config.metadata.symbol,
				uri: config.metadata.uri,
			}))
		}


		if (config.mintTotalSupply) {
			txn.add(createAssociatedTokenAccountInstruction(owner, ata, owner, tokenMint, TOKEN_2022_PROGRAM_ID))

			if (isDefaultFrozen)
				txn.add(createThawAccountInstruction(ata, tokenMint, owner, [], TOKEN_2022_PROGRAM_ID))

			txn.add(createMintToInstruction(tokenMint, ata, owner, config.totalSupply, [], TOKEN_2022_PROGRAM_ID))
		}

		const bhash = await this.connection.getLatestBlockhash("confirmed")
		txn.feePayer = owner
		txn.recentBlockhash = bhash.blockhash
		return txn
	}

	async getMintToTransaction(owner: web3.PublicKey, tokenMint: web3.PublicKey, amount: number, program = TOKEN_2022_PROGRAM_ID) {
		const txn = new web3.Transaction()
		const ata = getAssociatedTokenAddressSync(tokenMint, owner, true, program)

		const dstIfo = await this.connection.getAccountInfo(ata, "confirmed")
		if (!dstIfo) {
			txn.add(createAssociatedTokenAccountInstruction(owner, ata, owner, tokenMint, program))
		}

		txn.add(createMintToInstruction(tokenMint, ata, owner, amount, [], program))

		const bhash = await this.connection.getLatestBlockhash("confirmed")
		txn.feePayer = owner
		txn.recentBlockhash = bhash.blockhash
		return txn
	}

	async getSetAuthorityTransaction(owner: web3.PublicKey, mint: web3.PublicKey, authority: AuthorityType, newAuthority: web3.PublicKey | null, programID = TOKEN_2022_PROGRAM_ID, priorityFee: number = 100_000_000_000) {
		const txn = new web3.Transaction()
		txn.add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: 10_000}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: Math.floor(priorityFee / 10_000)})
		)
		txn.add(createSetAuthorityInstruction(mint, owner, authority, newAuthority, [], programID))

		const bhash = await this.connection.getLatestBlockhash("confirmed")
		txn.feePayer = owner
		txn.recentBlockhash = bhash.blockhash

		console.log("getSetAuthorityTransaction", txn)
		return txn
	}

	async getRevokeAuthorityTransaction(owner: web3.PublicKey, mint: web3.PublicKey, authority: AuthorityType, programID = TOKEN_2022_PROGRAM_ID, priorityFee: number = 100_000_000_000) {
		return this.getSetAuthorityTransaction(owner, mint, authority, null, programID, priorityFee)
	}

	async getUpdateTransferFeeTransaction(config: web3.PublicKey, owner: web3.PublicKey, transferFeeBasisPoints: number, maximumFee: bigint, priorityFee: number) {
		const txn = new web3.Transaction()
		txn.add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: 10_000}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: Math.floor(priorityFee / 10_000)})
		)
		txn.add(createSetTransferFeeInstruction(config, owner, transferFeeBasisPoints, maximumFee, [], TOKEN_2022_PROGRAM_ID))

		const bhash = await this.connection.getLatestBlockhash("confirmed")
		txn.feePayer = owner
		txn.recentBlockhash = bhash.blockhash
		return txn
	}

	async getUpdateInterestBearingConfigTransaction(config: web3.PublicKey, owner: web3.PublicKey, rate: number, priorityFee: number) {
		const txn = new web3.Transaction()
		txn.add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: 10_000}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: Math.floor(priorityFee / 10_000)})
		)
		txn.add(createUpdateRateInterestBearingMintInstruction(config, owner, rate))

		const bhash = await this.connection.getLatestBlockhash("confirmed")
		txn.feePayer = owner
		txn.recentBlockhash = bhash.blockhash
		return txn
	}

	async accountExists(account: web3.PublicKey) {
		const acc = await this.connection.getAccountInfo(account, "confirmed").catch(e => {
		})
		return !!acc;
	}
}