import {web3} from "@project-serum/anchor";
import {Numberu64, Schedule} from "@bonfida/token-vesting"
import {createAssociatedTokenAccountInstruction, getAssociatedTokenAddress, getAssociatedTokenAddressSync} from "@solana/spl-token";
import {PublicKey, SystemProgram, SYSVAR_CLOCK_PUBKEY, TransactionInstruction} from "@solana/web3.js";
import {createInitInstruction} from "@bonfida/token-vesting/src/instructions";
import {getContractInfo} from "@bonfida/token-vesting/src/main";
import {ContractInfo} from "@bonfida/token-vesting/src/state";


const PROGRAM_ID = new web3.PublicKey("Lock1zcQFoaZmTk59sr9pB5daFE6Cs1K5eWyRLF1eju")

export class Seed {
	mint;
	releaseTime: Numberu64;

	constructor(mint: web3.PublicKey, releaseTime: Numberu64) {
		this.mint = mint;
		this.releaseTime = releaseTime;
	}

	async toString() {
		const encoder = new TextEncoder();
		const dataUint8 = encoder.encode(JSON.stringify({
			"mint": this.mint.toString(),
			"time": this.releaseTime.toString()
		}));
		const hashBuffer = await crypto.subtle.digest('SHA-256', dataUint8);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
	}
}

export class FluxTokenLockerClient {
	connection: web3.Connection
	programID: web3.PublicKey = PROGRAM_ID

	constructor(connection: web3.Connection) {
		this.connection = connection
	}

	setProgramID(programID: web3.PublicKey) {
		this.programID = programID
	}

	async lockers(mint: web3.PublicKey) {
		const resp = await this.connection.getProgramAccounts(this.programID, {
			commitment: 'processed',
			filters: [
				{
					memcmp: {
						offset: 32,
						bytes: mint.toString(),
					},
				},
				{
					memcmp: {
						offset: 32 + 32 + 1,
						bytes: "",
					},
				},
			],
		})
		return resp.map((m) => {
			return {pubkey: m.pubkey, account: ContractInfo.fromBuffer(m.account.data)}
		})
	}

	async locker(locker: web3.PublicKey) {
		return await getContractInfo(this.connection, locker);
	}

	async create(payer: web3.PublicKey, owner: web3.PublicKey, mint: web3.PublicKey, tokenProgram: web3.PublicKey, schedules: Schedule[]): Promise<web3.TransactionInstruction[]> {
		const seedWord = await new Seed(mint, schedules[0].releaseTime).toString();
		return this._create(Buffer.from(seedWord), payer, owner, mint, tokenProgram, schedules)
	}

	async unlock(mint: web3.PublicKey, tokenProgram: web3.PublicKey, schedule: Schedule): Promise<web3.TransactionInstruction[]> {
		const seedWord = await new Seed(mint, schedule.releaseTime).toString();
		return this._unlock(Buffer.from(seedWord), mint, tokenProgram)
	}


	/**
	 * This function can be used to lock tokens
	 * @param seedWord Seed words used to derive the vesting account
	 * @param payer The fee payer of the transaction
	 * @param sourceTokenOwner The owner of the source token account (i.e where locked tokens are originating from)
	 * @param possibleSourceTokenPubkey The source token account (i.e where locked tokens are originating from), if null it defaults to the ATA
	 * @param destinationTokenPubkey The destination token account i.e where unlocked tokens will be transfered
	 * @param mintAddress The mint of the tokens being vested
	 * @param schedules The array of vesting schedules
	 * @param tokenProgram The token program for the mint
	 * @returns An array of `TransactionInstruction`
	 */
	async _create(
		seedWord: Buffer | Uint8Array,
		payer: PublicKey,
		sourceTokenOwner: PublicKey,
		mintAddress: PublicKey,
		tokenProgram: PublicKey,
		schedules: Array<Schedule>,
	): Promise<Array<TransactionInstruction>> {
		const sourceTokenPubkey = getAssociatedTokenAddressSync(mintAddress, sourceTokenOwner, true, tokenProgram)
		const destinationTokenPubkey = sourceTokenPubkey


		// Find the non reversible public key for the vesting contract via the seed
		seedWord = seedWord.slice(0, 31);
		const [vestingAccountKey, bump] = PublicKey.findProgramAddressSync([seedWord], this.programID);

		const vestingTokenAccountKey = await getAssociatedTokenAddress(
			mintAddress,
			vestingAccountKey,
			true,
			tokenProgram
		);

		seedWord = Buffer.from(seedWord.toString('hex') + bump.toString(16), 'hex');

		return [
			createInitInstruction(
				SystemProgram.programId,
				this.programID,
				payer,
				vestingAccountKey,
				[seedWord],
				schedules.length,
			),
			createAssociatedTokenAccountInstruction(
				payer,
				vestingTokenAccountKey,
				vestingAccountKey,
				mintAddress,
				tokenProgram
			),
			this.createCreateInstruction(
				this.programID,
				tokenProgram,
				vestingAccountKey,
				vestingTokenAccountKey,
				sourceTokenOwner,
				sourceTokenPubkey,
				destinationTokenPubkey,
				mintAddress,
				schedules,
				[seedWord],
			),
		];
	}


	createCreateInstruction(
		vestingProgramId: PublicKey,
		tokenProgramId: PublicKey,
		vestingAccountKey: PublicKey,
		vestingTokenAccountKey: PublicKey,
		sourceTokenAccountOwnerKey: PublicKey,
		sourceTokenAccountKey: PublicKey,
		destinationTokenAccountKey: PublicKey,
		mintAddress: PublicKey,
		schedules: Array<Schedule>,
		seeds: Array<Buffer | Uint8Array>,
	): TransactionInstruction {
		const buffers = [
			Buffer.from(Uint8Array.from([1]).buffer),
			Buffer.concat(seeds),
			mintAddress.toBuffer(),
			destinationTokenAccountKey.toBuffer(),
		];

		schedules.forEach(s => {
			buffers.push(s.toBuffer());
		});

		const data = Buffer.concat(buffers);
		const keys = [
			{
				pubkey: tokenProgramId,
				isSigner: false,
				isWritable: false,
			},
			{
				pubkey: vestingAccountKey,
				isSigner: false,
				isWritable: true,
			},
			{
				pubkey: vestingTokenAccountKey,
				isSigner: false,
				isWritable: true,
			},
			{
				pubkey: sourceTokenAccountOwnerKey,
				isSigner: true,
				isWritable: false,
			},
			{
				pubkey: sourceTokenAccountKey,
				isSigner: false,
				isWritable: true,
			},
			{
				pubkey: mintAddress,
				isSigner: false,
				isWritable: false,
			},
		];
		return new TransactionInstruction({
			keys,
			programId: vestingProgramId,
			data,
		});
	}

	/**
	 * This function can be used to unlock vested tokens
	 * @param seedWord Seed words used to derive the vesting account
	 * @param mintAddress The mint of the vested tokens
	 * @param tokenProgram The token program for the mint
	 * @returns An array of `TransactionInstruction`
	 */
	async _unlock(
		seedWord: Buffer | Uint8Array,
		mintAddress: PublicKey,
		tokenProgram: PublicKey
	): Promise<Array<TransactionInstruction>> {
		seedWord = seedWord.slice(0, 31);
		const [vestingAccountKey, bump] = PublicKey.findProgramAddressSync([seedWord], this.programID);
		seedWord = Buffer.from(seedWord.toString('hex') + bump.toString(16), 'hex');

		const vestingTokenAccountKey = await getAssociatedTokenAddress(
			mintAddress,
			vestingAccountKey,
			true,
			tokenProgram
		);

		console.log("VestingAccountKey", vestingAccountKey.toString())
		const accInfo = await this.connection.getAccountInfo(vestingAccountKey, "confirmed");
		if (!accInfo) {
			throw new Error('Vesting contract account is unavailable');
		}
		const vestingInfo = ContractInfo.fromBuffer(accInfo!.data);
		if (!vestingInfo) {
			throw new Error('Vesting contract account is not initialized');
		}

		// const vestingInfo = await getContractInfo(this.connection, vestingAccountKey);
		return [
			this.createUnlockInstruction(
				this.programID,
				tokenProgram,
				SYSVAR_CLOCK_PUBKEY,
				vestingAccountKey,
				vestingTokenAccountKey,
				vestingInfo.destinationAddress,
				mintAddress,
				[seedWord],
			),
		];
	}


	createUnlockInstruction(
		vestingProgramId: PublicKey,
		tokenProgramId: PublicKey,
		clockSysvarId: PublicKey,
		vestingAccountKey: PublicKey,
		vestingTokenAccountKey: PublicKey,
		destinationTokenAccountKey: PublicKey,
		mintAddress: PublicKey,
		seeds: Array<Buffer | Uint8Array>,
	): TransactionInstruction {
		const data = Buffer.concat([
			Buffer.from(Uint8Array.from([2]).buffer),
			Buffer.concat(seeds),
		]);

		const keys = [
			{
				pubkey: tokenProgramId,
				isSigner: false,
				isWritable: false,
			},
			{
				pubkey: clockSysvarId,
				isSigner: false,
				isWritable: false,
			},
			{
				pubkey: vestingAccountKey,
				isSigner: false,
				isWritable: true,
			},
			{
				pubkey: vestingTokenAccountKey,
				isSigner: false,
				isWritable: true,
			},
			{
				pubkey: destinationTokenAccountKey,
				isSigner: false,
				isWritable: true,
			},
			{
				pubkey: mintAddress,
				isSigner: false,
				isWritable: false,
			},
		];
		return new TransactionInstruction({
			keys,
			programId: vestingProgramId,
			data,
		});
	}


	async lockAuthority(mint: web3.PublicKey, tokenProgram: web3.PublicKey, schedule: Schedule, authorityType: number) {
		const seedWord = await new Seed(mint, schedule.releaseTime).toString();
		return this._lockAuthority(Buffer.from(seedWord), mint, tokenProgram, authorityType)
	}

	async unlockAuthority(mint: web3.PublicKey, tokenProgram: web3.PublicKey, schedule: Schedule, authorityType: number) {
		const seedWord = await new Seed(mint, schedule.releaseTime).toString();
		return this._unlockAuthority(Buffer.from(seedWord), mint, tokenProgram, authorityType)
	}


	async _lockAuthority(
		seedWord: Buffer | Uint8Array,
		mintAddress: PublicKey,
		tokenProgram: PublicKey,
		authorityType: number) {

	}

	async _unlockAuthority(
		seedWord: Buffer | Uint8Array,
		mintAddress: PublicKey,
		tokenProgram: PublicKey,
		authorityType: number) {

	}
}
